<template>
  <vue-if-bot>
    <div class="im__container">
      <section class="im__container__section">
        <h2 class="im__title">{{ $t("general_terms.title") }}</h2>
      </section>

      <section class="im__container__section">
        <h3>{{ $t("general_terms.scope") }}</h3>
        <p v-html="$t('general_terms.scope_text')"> </p>
      </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.scope_service") }}</h3>
        <p v-html="$t('general_terms.scope_text_service')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.conclusion_contract") }}</h3>
         <p v-html="$t('general_terms.conclusion_contract_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.client_obligations") }}</h3>
        <p v-html="$t('general_terms.client_obligations_text')"> </p>
    </section>
    <section class="im__container__section">
        <h3>{{ $t("general_terms.payment_terms") }}</h3>
        <p v-html="$t('general_terms.payment_terms_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.warranty") }}</h3>
        <p v-html="$t('general_terms.warranty_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.confidentiality") }}</h3>
        <p v-html="$t('general_terms.confidentiality_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.jurisdiction") }}</h3>
        <p v-html="$t('general_terms.jurisdiction_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.final_provisions") }}</h3>
        <p v-html="$t('general_terms.final_provisions_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.termination") }}</h3>
        <p v-html="$t('general_terms.termination_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.reference") }}</h3>
        <p v-html="$t('general_terms.reference_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.choice") }}</h3>
        <p v-html="$t('general_terms.choice_text')"> </p>
    </section>

    <section class="im__container__section">
        <h3>{{ $t("general_terms.provisions") }}</h3>
        <p v-html="$t('general_terms.provisions_text')"> </p>
    </section>

    </div>
  </vue-if-bot>
</template>

<script>
export default {
  name: "Impressum",
  components: {
    VueIfBot: () => import("vue-if-bot"),
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">
.im__container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #e4e4f1;
  padding-bottom: 60px;
  .im__container__section {
    padding: 0 194px 0 136px;
    .im__title {
        font-size: 26px;
      padding: 30px 0;
    }
    p {
      font-size: 16px;
      text-align: justify;
      padding-left: 20px;
    }
    h3 {
      font-size: 18px;
      padding-top: 30px;
    }
 
    
    a {
      color: #0cbcf5;
    }

  }

  @media (max-width: 1140px) {
    .im__container__section {
      padding: 20px;

      .im__container__div {
        background-color: #e4e4f1;
        width: 100%;
      }
    }
  }
}
</style>